(function() {
    this.saSlider = function(settings) {
        var _this = this;
        var mouseover = false;
        this.pauseTimeout = null;
        this.defaults = {
            infinite: true,
            centered: true,
            centerMode: true,
            pauseDelay: 6500, //ms
            autoplaySpeed: 3500, //ms
            animationSpeed: 500, //ms
            dots: true,
            arrows: true,
            dotsContainerHeight: 18,
            arrowHeight: 32,
            slidesToShow: 3,
            touchThreshold: 20,
            variableWidth: true,
            waitForAnimate: true,
            swipeToSlide: true,
            autoplay: true,
            fillWidth: false,
        };
        this.get = function(element){
            return $(settings.id + ' ' + element);
        };
        this.currentSlide = function(){
            return _this.slider.slick('slickCurrentSlide');
        };
        this.init = function () {
            // our slider plugin:
            // http://kenwheeler.github.io/slick/
            settings = $.extend( {}, this.defaults, settings );
            _this.slider = _this.get('');
            if(settings.arrows){
                settings.prevArrow = _this.get('.slick-prev')[0].outerHTML;
                settings.nextArrow = _this.get('.slick-next')[0].outerHTML;
                _this.get('.arrow-container').remove();
            }
            _this.slider.on({
                swipe: function(event, slick, direction){
                    _this.pause();
                    _this.unpause();
                },
                mouseenter: function(event){
                    _this.mouseover = true;
                    _this.pause();
                },
                mouseleave: function(event){
                    _this.mouseover = false;
                    _this.unpause();
                },
                afterChange: function(event, slick, currentSlide, nextSlide){
                    if(!$(this).hasClass('loaded')){
                        _this.resize();
                        $(this).addClass('loaded');
                    }
                    if(settings.onAfterChange) settings.onAfterChange(event, slick, currentSlide, nextSlide);
                },
            })
            .slick(settings)
            .slick('slickGoTo', 0);
            _this.arrows = _this.get('.slick-arrow');
            _this.arrows.saClick(function(event){
                _this.pause();
                _this.unpause();
            });
            $(window).resize(_this.resize);
        };
        this.unpause = function(){
            clearTimeout(_this.pauseTimeout);
            _this.pauseTimeout = setTimeout(function(){
                if(!_this.mouseover){
                    _this.slider.slick('slickPlay');
                }
            }, settings.pauseDelay);
        };
        this.pause = function(){
            _this.slider.slick('slickPause');
            clearTimeout(_this.pauseTimeout);
        };
        this.goTo = function(slideNumber){
            _this.slider.slick('slickGoTo', slideNumber);
        };
        this.resize = function(){
            if(settings.fillWidth){
                var containerWidth = _this.slider.parent().width();
                _this.get('.slide').css('width', containerWidth);
            } else {
                var containerHeight = _this.slider.parent().height();
                _this.get('.slide').each(function(){
                    var setHeight = settings.dots ? containerHeight - settings.dotsContainerHeight : containerHeight;
                    var ratio = $(this).width() / $(this).height();
                    var width = setHeight * ratio;
                    $(this).css({
                        height: setHeight,
                        width: setHeight * ratio,
                    });
                });
            }
        };
        _this.init();
    };
}());
