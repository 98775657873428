(function() {
    this.saSolo = function(settings) {
        var _this = this;
        _this.init = function(){
            $(document).ready(function(){
                _this.setup.all();
            });
        };

        _this.openDonateModal = function(){
            var iframe = $('.donate-modal iframe');
            iframe.attr('src',  iframe.data('src'));

            sa.alertModal({
                modal: '.donate-modal',
            });
        };
        _this.setup = {
            all: function(){
                _this.setup.search();
                _this.setup.sermonPage();
                _this.setup.generic();
                _this.setup.homeParallax();
                _this.setup.map();
                _this.setup.contactPage();
                _this.setup.shareModal();
                _this.setup.donationModal();
                _this.setup.sortOptions();
                _this.setup.webcastPoller();
                _this.setup.truncateAboutOnIndex();
            },
            truncateAboutOnIndex: function(){
                var about = $('.about-text.index-page');
                if(!about[0]) return;
                var aboutText = about.find('p');
                var aboutLink = about.find('a');
                var max_untruncated_height = parseInt(about.css('max-height'));
                aboutLink.saClick(function(){
                    about.css({
                        'height': max_untruncated_height,
                        'max-height': 'none',
                    }).addClass('open').removeClass('truncate');
                    setTimeout(function(){
                        about.css('height', aboutText.height() + 10);
                    }, 1);
                    setTimeout(function(){
                        about.css('height', 'auto');
                    }, 1000);
                });
                var checkTruncation = function(){
                    if(about.hasClass('open')) return;
                    if(aboutText.height() > max_untruncated_height){
                        about.addClass('truncate');
                    } else {
                        about.removeClass('truncate');
                    }
                };
                checkTruncation();
                $(window).on('resize', checkTruncation);
            },
            webcastPoller: function(){
                sa.webcasts.subscribeToWebcastCheck( function(){
                    var online = sa.webcasts.getWebcastsInProgressBySource(settings.broadcaster);
                    var notification = $('.webcast-status');
                    var visible = !notification.hasClass('offline');
                    if(online){
                        if(!visible) notification.removeClass('offline');
                    } else {
                        if(visible) notification.addClass('offline');
                    }
                    var onWebcastPage = $('.webcast-online, .webcast-offline').length; // check for webcast page existance
                    if(onWebcastPage) {
                        var webcastShown = $('.webcast-online').length; // check if webcast is showing on the page
                        var statusChanged = (online && !webcastShown) || (!online && webcastShown);
                        if(statusChanged){
                            location.reload();
                        } else {
                            $('.total-views').text(online.total_count);
                        }
                    }
                });
            },
            shareModal: function(){
                var openButton = $('.solo-share-button');
                if(!openButton.length) return;

                openButton.saClick(function(){
                    $('.share-modal').addClass('show');
                    sa.alertModal({
                        modal: '.solo-share-modal',
                    });
                });
                $('.solo-share-modal .dismiss').saClick(function(){
                    $('.solo-share-modal').trigger('close');
                });
            },
            donationModal: function(){
                var openButton = $('.solo-donate-button');
                if(!openButton.length) return;

                if(settings.donate){
                    _this.openDonateModal();
                }
                openButton.saClick(function(){
                    _this.openDonateModal();
                });
                $('.donate-modal .modal-close-button, .donate-modal .modal-background').saClick(function(){
                    $('.donate-modal').trigger('close');
                });
            },
            sortOptions: function(){
                var options = $('.solo-sorting-options');
                if(!options.length) return;

                $('.solo-sorting-options li').saClick(function(){
                    if($(this).hasClass('disabled')) return;
                    var val = $(this).data('value');
                    sa.urlHelpers.setUrlWithNewParameters({ sb: val });
                });
            },
            search: function(){
                var search = function(){
                    var searchValue = $('.solo-sermon-search input').val();
                    if(searchValue) searchValue += '/';
                    window.open(settings.searchURL + searchValue,"_self");
                };
                $('.search-close-button').saClick(search);
                sa.search.subscribe($('.sermon-search'), search);
            },
            contactPage: function(){
                var sendMessageButton = $('.send-contact-email');
                if(!sendMessageButton.length) return;
                var form = $('#send-message-form');
                $('#send-message-form .dismiss').saClick(function(){
                    form.removeClass('send-error');
                });
                form.on('submit', function(){
                    form.removeClass('send-error send-success');
                    sendMessageButton.prop('disabled', true);
                    event.preventDefault();
                    var name = $('#name').val();
                    var email = $('#email').val();
                    var message = $('#message-body').val();
                    var data = {
                        message: message,
                        name: name,
                        email: email,
                    };
                    $.ajaxSetup({
                        headers: { 'X-CSRFToken': Cookies.get('csrftoken') }
                    });
                    $.ajax({
                        url: settings.sendMessageURL,
                        type: 'POST',
                        data: data,
                        success: function(data){
                            form.addClass('send-success');
                            $('.send-message-form input, .send-message-form button, .send-message-form textarea').prop('disabled', true);
                        },
                        error: function(data){
                            console.error(data);
                            sendMessageButton.prop('disabled', false);
                            form.addClass('send-error');
                        },
                    });
                });
            },
            sermonPage: function(){
                // not a sermon page
                if(!$('.solo-hero.solo-sermon').length) return;
                // webcast page
                if($('.solo-webcast-page-metadata').length) return;
                // no sermon found
                if($('.no-sermon').length) return;

                var hasVideo = $('.display-video').length;
                var setFocus = function(video){
                    if(hasVideo){
                        sa.videos[0].setGlobalFocus(video);
                    }
                    if(sa.players[0]){
                        sa.players[0].setGlobalFocus(!video);
                    }
                };
                setFocus(hasVideo);
                var switchMediaButton = $('.switch-media');
                if(!switchMediaButton.length) return;
                var switchMedia = function(event, mediaType){
                    mediaType = $(this).data('media-type') || mediaType;
                    var current = $('.audio-video-blocks').hasClass('display-video') ? 'video' : 'audio';
                    if(current == mediaType) return;
                    sa.video.pauseAll();
                    sa.audio.pauseAll();
                    $('.audio-video-blocks').removeClass('display-video display-audio').addClass('display-' + mediaType);
                    sa.players[0].waveform.resize();
                    setFocus(mediaType == 'video');
                };

                switchMediaButton.saClick(switchMedia);
                $('*').keysEvent([sa.keycodes.v], 'keydown', function(event){
                    switchMedia(event, 'video');
                }, false, true).keysEvent([sa.keycodes.a], 'keydown', function(event){
                    switchMedia(event, 'audio');
                }, false, true);
            },
            generic: function(){
                $('.solo-nav-toggle').saClick(function(){
                    $('body').toggleClass('solo-nav-open');
                    $('.mobile-translation-button').removeClass('active');
                });
                $('.mobile-translation-button').saClick(function(){
                    $('body').removeClass('solo-nav-open');
                });
            },
            homeParallax: function(){
                if(!$('.parallax-block-background').length) return;

                var updateParallax = function(){
                    var containerTop = $('.solo-parallax').offset().top;
                    var scrollPosition = $('html').scrollTop();
                    var containerOffscreen = 1 - (scrollPosition / containerTop);

                    var blockHeight = $('.solo-parallax').outerHeight();
                    var windowWidth = window.innerWidth;

                    var startPosition = -0.5 * ((windowWidth * 0.5625 ) - blockHeight);
                    var topPosition = containerOffscreen * startPosition;
                    $('.parallax-block-background').css('top', topPosition);
                };
                $(window).on('resize scroll', updateParallax);
                updateParallax();
            },
            map: function(){
                if(!$('#leaflet-map').length) return;

                var mobile = function(){
                    return $(window).outerWidth() <= 640;
                };
                var largeOffset = [.05, .05];
                var mobileOffset = [.05, 0];

                _this.map = new saMap({
                    id: 'leaflet-map',
                    zoom: 11,
                    startingOffset: mobile() ? mobileOffset : largeOffset,
                });
                _this.map.addMarker({
                    lat: _this.map.coords[0],
                    lng: _this.map.coords[1],
                    name: '',
                });
                var offsetMap = function(){
                    if(mobile()){
                        _this.map.offset(mobileOffset);
                    } else {
                        _this.map.offset(largeOffset);
                    }
                };
                $(window).on('resize', offsetMap);
                offsetMap();
            },
        };
        _this.init();
    };
}());
