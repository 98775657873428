(function() {
    this.saMap = function(settings) {
        var _this = this;
        this.markers = [];
        this.defaultSettings = {
            zoom: 9,
            minZoom: 4,
            maxZoom: 18,
            allowMovement: false,
        };
        this.attribution = function() {
            var copyrightSymbol = '&copy; ';
            var osmLink = '<a href="http://www.openstreetmap.org/copyright" target="blank" aria-label="' + gettext("Copyright Open Street Map contributors") + '">OSM</a>';
            var stadiaLink = '<a href="https://stadiamaps.com/" target="blank" aria-label="' + gettext("Copyright Stadia Maps") + '">Stadia Maps</a>';

            var osm = copyrightSymbol + interpolate(gettext('%s contributors'), [osmLink]);
            var stadia = copyrightSymbol + stadiaLink;

            return osm + ', ' + stadia;
        };
        this.init = function () {
            _this.mapId = settings.id;
            _this.$map = $('#' + _this.mapId);
            _this.coords = [_this.$map.data('lat'), _this.$map.data('lng')];

            if(typeof _this.coords[0] != 'number' || typeof _this.coords[1] != 'number') return;
            if(settings.startingOffset){
                _this.startingCoords = [_this.coords[0] + settings.startingOffset[0], _this.coords[1] + settings.startingOffset[1]];
            } else {
                _this.startingCoords = _this.coords;
            }
            _this.currentCoords = _this.startingCoords;
            settings.zoom = settings.zoom || _this.defaultSettings.zoom;
            settings.allowMovement = settings.allowMovement || _this.defaultSettings.allowMovement;
            if(settings.allowMovement){
                settings.minZoom = settings.minZoom || _this.defaultSettings.minZoom;
                settings.maxZoom = settings.maxZoom || _this.defaultSettings.maxZoom;
            } else {
                settings.minZoom = settings.maxZoom = settings.zoom;
            }
            _this.pinIcon = $('.map-icon-container [data-map-icon="pin"]').html();
            $('.map-icon-container').remove();

            _this.initMap();
        };
        this.addMarker = function(pinInfo){
            if(!_this.leafletMap) return;
            var marker = L.marker([pinInfo.lat, pinInfo.lng], {
                title: pinInfo.name,
                icon: L.divIcon({
                    className: 'broadcaster-marker',
                    iconSize: [30, 40],
                    iconAnchor: [15, 40],
                    popupAnchor: [0, -50],
                    html: _this.pinIcon
                }),
                alt: pinInfo.name,
                riseOnHover: true,
                keyboard: false,
            });
            _this.leafletMap.addLayer(marker);
            _this.markers.push(marker);
        };
        this.moveMap = function(lat, lng){
            if(!_this.leafletMap) return;
            _this.leafletMap.setView([lat, lng], settings.zoom);
        };
        this.offset = function(offset){
            if(!_this.leafletMap) return;
            var newLoc = [_this.coords[0] + offset[0], _this.coords[1] + offset[1]];
            if(_this.currentCoords != newLoc){
                _this.currentCoords = newLoc;
                _this.leafletMap.setView(newLoc, settings.zoom);
            }
        };
        this.initMap = function(){
            _this.leafletMap = L.map(settings.id, {
                center: _this.startingCoords,
                zoomControl: settings.allowMovement,
                keyboard: settings.allowMovement,
                scrollWheelZoom: settings.allowMovement,
                zoom: settings.zoom,
                dragging: settings.allowMovement,
                trackResize: true,
                doubleClickZoom: settings.allowMovement,
                minZoom: settings.minZoom,
                maxZoom: settings.maxZoom,
                maxBounds: ([
                    [-90, 180], // south west
                    [90, -180] // north east
                ]),
            });
            if(settings.allowMovement){
                _this.leafletMap.addControl(L.control.zoom({
                    position: 'bottomright',
                    keyboard: true
                }));
            }
            _this.leafletMap.attributionControl.addAttribution(_this.attribution());
            var url =
             'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=dc14ae6d-9e26-4857-b044-986f247d1049';
            L.tileLayer(url, {
                maxZoom: settings.maxZoom,
                id: '',
                accessToken: '',
                noWrap: true,
            }).addTo(_this.leafletMap);
        };
        _this.init();
    };
}());
